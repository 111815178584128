import React, { useEffect, useRef, useState } from "react";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/AppNav";
import Footer from "components/App/Footer";

import LoadingModal from "components/fpmakerSignUp/LoadingModal";

const FpmakerSignUp = () => {
  const navbarRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    storeName: "",
    ownerName: "",
    ownerContact: "",
    location: "",
    shopContact: "",
    instagramId: "",
    businessNumber: "",
    referral: "",
    crm: "",
    crmOther: "",
    career: "",
    careerOther: "",
    agreement: false,
    paymentMethod: "카드",
  });

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const locations = [
    "서울",
    "경기",
    "인천",
    "부산",
    "대구",
    "광주",
    "대전",
    "울산",
    "세종",
    "강원",
    "충청",
    "전북",
    "전남",
    "경북",
    "경남",
    "제주",
    "기타",
  ];

  const crmList = [
    "없음",
    "아하소프트",
    "공비서",
    "팅커뷰",
    "콜라보살롱",
    "핸드SOS",
    "기타",
  ];

  const careers = [
    "창업 예정",
    "신구(1년 이내)",
    "5년 이내",
    "10년 이내",
    "기타",
  ];

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // 연락처 필드에서 숫자만 허용하도록 필터링
    if (name === "ownerContact" || name === "shopContact") {
      const numericValue = value.replace(/[^0-9]/g, ""); // 숫자 이외의 문자 제거
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.agreement) {
      alert("개인정보 수집 및 이용에 동의해야 합니다.");
      return;
    }

    /*setIsLoading(true);

    setTimeout(async () => {
      setIsLoading(false);
    }, 6000);
    return;*/

    // 디스코드 웹훅 URL
    const webhookUrl =
      "https://discord.com/api/webhooks/1281217584401879084/7H41TS2JMMRmhw9dJ6hz-lKVEoXy0xcaNCPCWnCeU4ZfQ3IoDHWOAIVQbs5r10dEjZww";

    // 전송할 데이터 구성
    const payload = {
      content: `신규 가맹점 신청서 접수:
 - 가맹점 명: ${formData.storeName}
 - 대표자명: ${formData.ownerName}
 - 대표자 연락처: ${formData.ownerContact}
 - 지역: ${formData.location}
 - 가입비 결제 방식 : ${formData.paymentMethod}
 - 샵 연락처: ${formData.shopContact}
 - 인스타그램 ID: ${formData.instagramId}
 - 사업자등록번호: ${formData.businessNumber}
 - 추천샵 또는 추천인: ${formData.referral}
 - CRM: ${formData.crm === "기타" ? formData.crmOther : formData.crm}
 - 경력사항: ${
   formData.career === "기타" ? formData.careerOther : formData.career
 }`,
    };

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("신청서가 성공적으로 제출되었습니다.");
      } else {
        alert("웹훅 전송에 실패했습니다.");
      }
    } catch (error) {
      console.error("웹훅 전송 중 오류 발생:", error);
      alert("웹훅 전송 중 오류가 발생했습니다.");
    }

    setTimeout(async () => {
      setIsLoading(false);
    }, 6000);
  };

  // 인라인 스타일 정의
  const styles = {
    mainFormContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "20px",
      backgroundColor: "#f9f9f9",
    },
    formWrapper: {
      background: "#ffffff",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "30px",
      maxWidth: "600px",
      width: "100%",
    },
    header: {
      textAlign: "center",
      marginBottom: "20px",
      color: "#333",
    },
    formGroup: {
      marginBottom: "15px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
      color: "#555",
    },
    requiredLabel: {
      display: "block",
      marginBottom: "5px",
      color: "#555",
      fontWeight: "bold",
    },
    asterisk: {
      color: "red",
      marginLeft: "5px",
    },
    input: {
      width: "100%",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      fontSize: "16px",
    },
    agreementSection: {
      backgroundColor: "#f0f0f0",
      padding: "10px",
      borderRadius: "5px",
      marginTop: "20px",
    },
    agreementDescription: {
      fontSize: "14px",
      color: "#666",
      marginTop: "10px",
    },
    submitButton: {
      width: "100%",
      padding: "15px",
      backgroundColor: "#FF5D7A",
      border: "none",
      borderRadius: "8px",
      color: "#ffffff",
      fontSize: "18px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      marginTop: "20px",
    },
  };

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} section={""} isMobile={isMobile} />
      <LoadingModal show={isLoading} />
      <main style={styles.mainFormContainer}>
        <div style={styles.formWrapper}>
          <h1 style={styles.header}>가입 신청서</h1>
          <form onSubmit={handleSubmit}>
            {/* 가맹점 명 */}
            <div style={styles.formGroup}>
              <label style={styles.requiredLabel}>
                가맹점 명<span style={styles.asterisk}>*</span>
              </label>
              <input
                type="text"
                name="storeName"
                value={formData.storeName}
                onChange={handleChange}
                required
                style={styles.input}
              />
            </div>

            {/* 대표자명 */}
            <div style={styles.formGroup}>
              <label style={styles.requiredLabel}>
                대표자명
                <span style={styles.asterisk}>*</span>
              </label>
              <input
                type="text"
                name="ownerName"
                value={formData.ownerName}
                onChange={handleChange}
                required
                style={styles.input}
              />
            </div>

            {/* 대표자 연락처 */}
            <div style={styles.formGroup}>
              <label style={styles.requiredLabel}>
                대표자 연락처
                <span style={styles.asterisk}>*</span>
              </label>
              <input
                type="tel"
                name="ownerContact"
                value={formData.ownerContact}
                onChange={handleChange}
                required
                style={styles.input}
              />
            </div>

            {/* 지역 선택 */}
            <div style={styles.formGroup}>
              <label style={styles.requiredLabel}>
                가맹점이 위치한 지역
                <span style={styles.asterisk}>*</span>
              </label>
              <select
                name="location"
                value={formData.location}
                onChange={handleChange}
                required
                style={styles.input}
              >
                <option value="">지역 선택</option>
                {locations.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>

            {/* 가입비 결제 항목 */}
            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "10px",
                  fontWeight: "bold",
                  color: "#555",
                }}
              >
                가입비 결제 방식
                <span style={{ color: "red", marginLeft: "5px" }}>*</span>
              </label>
              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  gap: "20px",
                  borderRadius: "5px",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    color: "#555",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="카드"
                    checked={formData.paymentMethod === "카드"}
                    onChange={handleChange}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                  />
                  카드
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    color: "#555",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="현금"
                    checked={formData.paymentMethod === "현금"}
                    onChange={handleChange}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                  />
                  현금
                </label>
              </div>
            </div>

            {/* 샵 연락처 */}
            <div style={styles.formGroup}>
              <label style={styles.label}>샵 연락처:</label>
              <input
                type="tel"
                name="shopContact"
                value={formData.shopContact}
                onChange={handleChange}
                style={styles.input}
              />
            </div>

            {/* 샵 Instagram ID */}
            <div style={styles.formGroup}>
              <label style={styles.label}>샵 Instagram ID:</label>
              <input
                type="text"
                name="instagramId"
                value={formData.instagramId}
                onChange={handleChange}
                style={styles.input}
              />
            </div>

            {/* 사업자등록번호 */}
            <div style={styles.formGroup}>
              <label style={styles.label}>사업자등록번호:</label>
              <input
                type="text"
                name="businessNumber"
                value={formData.businessNumber}
                onChange={handleChange}
                style={styles.input}
              />
            </div>

            {/* CRM 선택 */}
            <div style={styles.formGroup}>
              <label style={styles.label}>
                이용 중인 고객관리프로그램(CRM):
              </label>
              <select
                name="crm"
                value={formData.crm}
                onChange={handleChange}
                style={styles.input}
              >
                <option value="">선택</option>
                {crmList.map((crm) => (
                  <option key={crm} value={crm}>
                    {crm}
                  </option>
                ))}
              </select>
              {formData.crm === "기타" && (
                <input
                  type="text"
                  name="crmOther"
                  value={formData.crmOther}
                  onChange={handleChange}
                  placeholder="CRM 기타 입력"
                  style={{ ...styles.input, marginTop: "10px" }}
                />
              )}
            </div>

            {/* 경력 사항 선택 */}
            <div style={styles.formGroup}>
              <label style={styles.label}>경력 사항:</label>
              <select
                name="career"
                value={formData.career}
                onChange={handleChange}
                style={styles.input}
              >
                <option value="">경력 선택</option>
                {careers.map((career) => (
                  <option key={career} value={career}>
                    {career}
                  </option>
                ))}
              </select>
              {formData.career === "기타" && (
                <input
                  type="text"
                  name="careerOther"
                  value={formData.careerOther}
                  onChange={handleChange}
                  placeholder="경력 기타 입력"
                  style={{ ...styles.input, marginTop: "10px" }}
                />
              )}
            </div>

            {/* 추천샵 또는 추천인 */}
            <div style={styles.formGroup}>
              <label style={styles.label}>추천샵 또는 추천인:</label>
              <input
                type="text"
                name="referral"
                value={formData.referral}
                onChange={handleChange}
                style={styles.input}
              />
            </div>

            {/* 개인정보 수집 및 이용 동의 */}
            <div style={styles.agreementSection}>
              <label style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  name="agreement"
                  checked={formData.agreement}
                  onChange={handleChange}
                  style={{ marginRight: "10px" }}
                />
                아래 내용을 확인하였으며, 핑프 및 핑프메이커 이용을 위한
                개인정보 수집 ∙ 이용에 동의합니다 (필수)
              </label>
              <div style={styles.agreementDescription}>
                <p>
                  {`(주)보트르내일은 아래와 같이 개인정보를 처리합니다.`} <br />
                  {`귀하는
                  위와 같은 필요 최소한의 개인정보 수집 ∙ 이용에 동의하지 않을
                  권리가 있습니다.`}{" "}
                  <br />
                  {`(다만, 동의를 해야만 핑프 및 핑프메이커
                  서비스를 무상 제공드릴 수 있음을 양지하시기 바랍니다.)`}{" "}
                  <br />
                  <br />
                  {`✓ 예약금은 기본 20,000원으로 세팅되며, 웹에서 변경이
                  가능합니다.`}{" "}
                  <br />
                  {`(내샵관리 > 기본정보 > 예약금 금액 변경)`} <br />
                  {`✓시술 메뉴 별 소요시간은 기본 60분으로 세팅되며, 메뉴 별
                  소요시간이 상이할 경우 변경 부탁 드립니다. `}
                  <br />
                  {`(입력한 소요시간에 따라 예약 시간이 블락됩니다.)`} <br />
                  {`✓ 예약금 환불은 핑프의
                  정책 기준으로 환불되며, 취소 금액 또는 노쇼 발생시 익월에
                  환급해 드립니다.`}
                  <br />
                  {`(2일 전 취소 : 100% 환불, 1일 전 취소 : 50%
                  환불, 당일 취소 및 노쇼 : 환불 불가)`}
                  <br />
                  {`✓이달의 아트 미업로드 시, 핑프에서 외부 SNS를 참고하여 자체적으로 업로드 할 수 있습니다.`}
                  <br />
                  {`✓ 핑프가 신규 예약을 유치해드리기 때문에 예약 건당 중개수수료가 발생합니다. `}
                  <br />
                  {`(자세한 수수료는 핑프메이커 내 확인 가능합니다.)`}
                  <br />
                  {`✓ 발생하는 중개수수료는 샵 전용 쿠폰 및 핑프몰 혜택을 통해 페이백 해드립니다.`}
                  <br />
                  <br />
                  {`📌 문의 사항은 카카오채널에서 "핑프"를 검색해주시기 바랍니다.`}
                  <br />
                  {`📌 가입신청서 작성 후 최초 입점비 (현금 또는 카드) 150,000원이 발생합니다.`}
                </p>
              </div>
            </div>

            {/* 제출 버튼 */}
            <button type="submit" style={styles.submitButton}>
              가입 신청서 제출
            </button>
          </form>
        </div>
      </main>
      <Footer active={""} noWave={true} isMobile={isMobile} />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>핑프-핑거프린세스</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default FpmakerSignUp;
